import React from 'react';
import { Link } from 'react-router-dom';
import aboutImg from '../images/rice_4.png';

const About = () => {
  return (
    <>
      <div className="m-50 max-w-full p-2 md:p-12 h-5/6" id="about">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/3 flex flex-col lg:mx-4 my-14 justify-top">
            <img
              alt="about img"
              className="rounded-t float-right"
              src={aboutImg}
            />
          </div>
          <div
            className="flex-col my-4  text-left lg:my-0 lg:justify-end w-full lg:w-2/3 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h1 className="text-2xl md:text-3xl text-center lg:text-left primary-text font-bold">
            ABOUT THE COMPANY
            </h1>
            <div>
              <p className="my-3 text-l  md:text-xl  text-gray-600 font-semibold">
              Merchant Prince Holdings (MPH) is an independent related management company of  Marquee Commodity Group (MCG).  MPH, and its key advisors, with the support of MCG focus on the strategic marketing and promotion of MCG Global Agricultural and Exotic Fruits, Spices, Nuts, and Beverage Brands commodities. 
              </p>
              <p className="my-3 text-l  md:text-xl  text-gray-600 font-semibold">
              Key advisors of MPH provide professional insight, strategic investment, business advice, legal council , M&A evaluation, logistical and shipping support . These subject matter experts receive specified  royalty compensations . 
              </p>
              <p className="my-3 text-l  md:text-xl  text-gray-600 font-semibold">
              MPH is a commodity professional services holding corporation exclusively aligned with MCG commodity portfolio.
              </p>
              </div>
            <div>
              <p className="my-3 text-l  md:text-xl text-gray-600 font-semibold">
                Our corporate values:
              </p>
            </div>

            <ul className="my-3 text-l  md:text-xl primary-text font-semibold list-disc m-10 items-start">
              <li key={1}>Strict Quality Standards</li>
              <li key={2}>Leading Competitive Costs</li>
              <li key={3}>Superior Products</li>
              <li key={4}>Positive Stakeholder Relationships</li>
            </ul>
{/*             
            <Link
              to="/learn-more"
              className="text-white bg-[#4695CE] hover:bg-[#53B18D] inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
            >
              Learn more
              <svg
                className="w-4 h-4 ml-1 group-hover: translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
